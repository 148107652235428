<template>
  <div class="facilityBox">
    <!-- 合计 -->
    <div class="sumArea">
      <div class="settleBox">
        <el-row>
          <el-col :span="4">开票申请时间：<strong>{{
            wayBillTotal.JsDateTime
          }}</strong></el-col>
          <el-col :span="4">运单总数(条)：<strong>{{
            wayBillTotal.WayBillCount
          }}</strong></el-col>
          <el-col :span="4">运费(元)：/</el-col>
          <!-- <el-col :span="4">运费(元)：<strong>￥{{wayBillTotal.TotalFreight}}</strong></el-col> -->
          <el-col :span="4">服务费/税费(元)：<strong>￥{{ wayBillTotal.TotalTax }}</strong></el-col>
          <el-col :span="4">运单总金额(元)：<strong>￥{{ wayBillTotal.TotalAmount }}</strong></el-col>
        </el-row>
        <el-row>
          <el-col :span="4">货主单位：<strong>{{ consign.InvoiceTitleName }}</strong></el-col>
          <el-col :span="4">货主税号：<strong>{{
            consign.InvoiceTitleCreditCode
          }}</strong></el-col>
          <el-col :span="4">货主地址：<strong>{{
            consign.InvoiceTitleAddress
          }}</strong></el-col>
          <el-col :span="4">货主电话：<strong>{{ consign.InvoiceTitlePhone }}</strong></el-col>
          <el-col :span="4">货主开户行：<strong>{{
            consign.InvoiceTitleBankerName
          }}</strong></el-col>
        </el-row>
        <el-row>
          <el-col :span="4">货主账号：<strong>{{
            consign.InvoiceTitleBankerNumber
          }}</strong></el-col>
        </el-row>
      </div>
      <!-- 表格 -->
      <el-table :data="currentTabledata" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
        <el-table-column fixed="left" align="center" prop="index" label="序号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PrintTaskID" label="发票任务单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WayBillType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ConsignName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SupplierName" label="服务商单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WaybillCount" label="总计运单数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageAmount" label="运费" width="200">
          <template slot-scope="scope" width="200">
            <div style="color:#FF8B17;">
              /
              <!-- {{scope.row.CarriageAmount | formatMoney}} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TaxAmount" label="服务费/税费" width="200">
          <template slot-scope="scope" width="200">
            <div style="color:#FF8B17;">
              {{ scope.row.TaxAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayTotalAmount" label="运单总金额" width="200">
          <template slot-scope="scope" width="200">
            <div style="color:#FF8B17;">
              {{ scope.row.PayTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceAmount" label="发票面额" width="200">
          <template slot-scope="scope" width="200">
            <div style="color:#FF8B17;">
              {{ scope.row.InvoiceAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="FeeTypeName" label="开票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="_invoiceNumber" label="发票号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CreateTime" label="开票日期" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="250">
          <template slot-scope="scope">
            <el-button @click="_detail(scope.row, scope.$index)" type="primary" size="mini" title="查看详情">查看详情</el-button>
            <el-button @click="openUpdateInvoiceDialog(scope.row, scope.$index)" type="primary" size="mini"
              title="修改发票">修改发票
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination1" @current-change="handleCurrentChange1"
        :current-page.sync="pagination1.page" :page-size="pagination1.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination1.total">
      </el-pagination>
      <!-- 右下角按钮-->
      <div style="width:100%;text-align:center;" v-if="currentTabledata.length">
        <el-popover placement="bottom" title="驳回原因" width="200" trigger="click" :content="currentTabledata[0].Remark">
          <el-button type="primary" size="mini" slot="reference">
            驳回原因</el-button>
        </el-popover>
        <el-button type="primary" size="mini" @click="flag.sendBossDialog = true"
          :disabled="flag.sendInvoice ? true : false" style="margin-left:15px">发送至货主</el-button>
        <el-button type="primary" size="mini" @click="goBack" style="margin-left:15px">返回待办</el-button>
      </div>
    </div>

    <!-- 查看详情dialog -->
    <el-dialog :visible.sync="flag.detailDialog" width="80%">
      <span slot="title">发票详情</span>
      <!-- 顶部按钮 -->
      <div style="margin-bottom:16px;text-align:left;">
        <el-button type="primary" size="mini" @click="billDown">下载发票运单表格</el-button>
      </div>
      <!-- 货物承运表格展示 -->
      <el-table v-if="flag.waybillType == '01' && flag.taskSceneTypeID == '1'" :data="tableData"
        v-loading="flag.dialogLoading" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" height="800px">
        <el-table-column fixed="left" align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ConsignName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCodeHZ" label="货主税号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseAddressHZ" label="货主地址" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseContactsPhoneHZ" label="货主电话" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNameHZ" label="货主开户行" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNumberHZ" label="货主账号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskDatetime" label="申请开票时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LoadingDatetime" label="运输起始时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="运输结束时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverAllotCount" label="货品数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverCarryPrice" label="运输单价" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.DriverCarryPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              /
              <!-- {{scope.row.CarriageTotalPrice | formatMoney}} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="费率" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ServiceCharge" label="服务费/税费" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.ServiceCharge | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarNumber" label="车牌号" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="VehicleType" label="车辆类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)" show-overflow-tooltip>
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.DriverUserID)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="服务商单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="服务商税号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="服务商地址" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseContactsPhone" label="服务商电话" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerName" label="服务商开户行" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNumber" label="服务商账号" width="200" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <!-- 土石方表格承运 -->
      <el-table v-if="flag.waybillType == '01' && flag.taskSceneTypeID == '6'" :data="tableData"
        v-loading="flag.dialogLoading" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" height="800px">
        <el-table-column fixed="left" align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ConsignName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCodeHZ" label="货主税号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseAddressHZ" label="货主地址" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseContactsPhoneHZ" label="货主电话" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNameHZ" label="货主开户行" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNumberHZ" label="货主账号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskDatetime" label="申请开票时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LoadingDatetime" label="运输起始时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="运输结束时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverAllotCount" label="货品数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverCarryPrice" label="运输单价" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.DriverCarryPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              /
              <!-- {{scope.row.CarriageTotalPrice | formatMoney}} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="费率" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ServiceCharge" label="服务费/税费" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.ServiceCharge | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" label="含税金额" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="税率" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <p>{{ scope.row.DefaultRate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop=" NonTaxTotalAmount" label="不含税金额" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.NonTaxTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop=" TaxFee" label="税额" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TaxFee | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarNumber" label="车牌号" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="VehicleType" label="车辆类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)" show-overflow-tooltip>
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.DriverUserID)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="服务商单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="服务商税号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="服务商地址" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseContactsPhone" label="服务商电话" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerName" label="服务商开户行" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNumber" label="服务商账号" width="200" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <!-- 交通运输表格展示 -->
      <el-table
        v-if="(flag.waybillType == '02' && flag.taskSceneTypeID == '3') || $entrustSceneIds.includes(Number(flag.taskSceneTypeID))"
        :data="tableData" v-loading="flag.dialogLoading" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        height="800px">
        <el-table-column fixed="left" align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ConsignName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCodeHZ" label="货主税号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseAddressHZ" label="货主地址" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseContactsPhoneHZ" label="货主电话" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNameHZ" label="货主开户行" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNumberHZ" label="货主账号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskDatetime" label="申请开票时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LoadingDatetime" label="运输起始时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="运输结束时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverAllotCount" label="货品数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverCarryPrice" label="运输单价" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              /
              <!-- {{scope.row.CarriageTotalPrice | formatMoney}} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="费率" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ServiceCharge" label="服务费/税费" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.ServiceCharge | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" label="总金额" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarNumber" label="车牌号" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="VehicleType" label="车辆类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginAddress" label="起始地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)" show-overflow-tooltip>
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row.DriverUserID)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="服务商单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="服务商税号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="服务商地址" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseContactsPhone" label="服务商电话" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerName" label="服务商开户行" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNumber" label="服务商账号" width="200" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 修改发票dialog -->
    <el-dialog width="1400px" height="600px" class="updateInvoiceDialog" :visible.sync="flag.updateInvoiceDialog"
      :close-on-press-escape="false" :close-on-click-modal="false" v-if="flag.updateInvoiceDialog">
      <el-tabs v-model="flag.activeTab" type="card">
        <el-tab-pane label="电子发票" name="0">
          <vxe-table border resizable show-overflow :data="electronInvoiceList"
            :edit-config="{ trigger: 'click', mode: 'cell' }" class="update-special-table">
            <!-- <vxe-column type='checkbox' width='60'>
                    </vxe-column> -->
            <vxe-table-column align="center" field="index" title="序号" width="60"></vxe-table-column>
            <vxe-table-column align="center" field="InvoiceURL" title="发票图片" width="100">
              <template #default="{ row }">
                <el-image style="width:40px;height:40px;margin-top:5px;" :src="row.InvoiceImgUrl"
                  :preview-src-list="[row.InvoiceImgUrl]"></el-image>
                <!-- <el-link type="primary" @click="batchInvoiceAction(3, row)" v-else>查看发票</el-link> -->
              </template>
            </vxe-table-column>
            <vxe-table-column align="center" field="PrintTaskID" title="打票单号（必填）" width="150"
              :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
            <vxe-table-column align="center" field="InvoiceCode" title="发票代码（必填）" width="150"
              :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
            <vxe-table-column align="center" field="InvoiceNumber" title="发票号码（必填）" width="150"
              :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
            <vxe-table-column align="center" field="CreateTime" title="开具日期（必填）" width="150"
              :edit-render="{ autoselect: true }">
              <template #edit="{ row }">
                <el-input v-model="row.CreateTime" type="date" value-format="yyyy-MM-dd" />
              </template>
            </vxe-table-column>
            <vxe-table-column align="center" field="InvoiceAmount" title="开具金额（必填）" width="150"
              :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
            <vxe-table-column align="center" field="InvoiceTotalAmount" title="价税合计" width="150"
              :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
            <vxe-table-column align="center" field="CheckCode" title="校验码" width="150"
              :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
            <vxe-table-column align="center" field="StatusDesc" title="状态" width="150"></vxe-table-column>
            <vxe-table-column align="center" title="操作" width="250">
              <template #default="{ row }">
                <!-- <el-button type="primary" size="mini" @click="batchInvoiceAction(1, row)"
                  v-if="!row.InvoiceNumber && !row.InvoiceSerialNum && row.AutoStatus != 0">重开</el-button>
                <el-button type="primary" size="mini" @click="batchInvoiceAction(1, row)"
                  v-if="!row.InvoiceNumber && !row.InvoiceSerialNum && row.AutoStatus == 0">开票</el-button>
                <el-button type="primary" size="mini" @click="batchInvoiceAction(2, row)" v-if="row.InvoiceNumber"
                  disabled>冲红</el-button> -->
                <el-button type="primary" size="mini" @click="batchInvoiceAction(3, row.pdfUrl)" v-if="row.InvoiceNumber"
                  :disabled="!row.pdfUrl">下载pdf</el-button>
                <el-button type="primary" size="mini" @click="batchInvoiceAction(3, row.ofdUrl)" v-if="row.InvoiceNumber"
                  :disabled="!row.ofdUrl">下载ofd</el-button>
                <!-- <el-button type="primary" size="mini" @click="batchInvoiceAction(4, row)"
                  v-if="!row.InvoiceNumber && row.InvoiceSerialNum">查询</el-button> -->
              </template>
            </vxe-table-column>
          </vxe-table>
          <!-- 分页 -->
          <el-pagination background @current-change="handleCurrentChangeElectron" style="margin-top: 8px;"
            :current-page.sync="paginationElectron.page" :page-size="paginationElectron.pagesize"
            layout="total, prev, pager, next, jumper" :total="paginationElectron.total">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="纸质发票" name="1">
          <div style="height: 60vh;overflow-y: scroll;">
            <!-- 提示 -->
            <el-alert style="margin-bottom: 8px;" type="warning" :closable="false">
              <span slot="title">
                <span>温馨提示：1.上传发票，将自动OCR识别发票信息；2.上传异常、需手动填写发票3项信息，平台将人工审核，并在全国增值税发票查验平台校验真伪</span><br />
              </span>
            </el-alert>
            <input type="file" style="display:none" @change="handleInvoiceUpload" multiple ref="invoice" />
            <input type="file" style="display:none" @change="handleSpecialInvoiceUpload" multiple ref="specialInvoice" />
            <input type="file" style="display:none" @change="handleVoucherUpload" ref="voucher" />

            <!-- 常见发票 -->
            <!-- <strong style="font-size: 15px; font-weight: 600;">常见发票</strong>
            <el-button type="primary" style="margin: 16px 16px;" @click="$refs.invoice.click()"
              :loading="flag.uploadNormalInvoice" icon="el-icon-upload2">批量上传</el-button>
            <span>注明：最多一次可上传10张</span>
            <div class="uploadImgWrap">
              <div class="item" v-for="(item, index) in invoiceImgList" :key="index">
                <i class="delete el-icon-delete" @click="handleInvoiceDelete(item, index)"></i>
                <el-image class="img" :src="item.InvoiceImgUrl" :preview-src-list="[item.InvoiceImgUrl]">
                  <div slot="error" class="image-slot"
                    style="display: flex; justify-content: center; margin-top: 30px;">
                    <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
                  </div>
                </el-image>
                <el-tooltip :content="item.InvoiceImgUrl">
                  <p @click="downloadVoucher(item.InvoiceImgUrl)"
                    style="width: 180px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;color:blue">
                    {{ item.InvoiceImgUrl }}
                  </p>
                </el-tooltip>
              </div>
            </div>
            <el-pagination background @current-change="handleCurrentChange3" :current-page.sync="pagination3.page"
              :page-size="pagination3.pagesize" layout="total, prev, pager, next, jumper" :total="pagination3.total">
            </el-pagination> -->

            <hr style="margin-top: 16px;" />

            <!-- 特殊发票 -->
            <span style="font-size: 15px; font-weight: 600;">上传发票</span>
            <el-button type="primary" style="margin: 16px 16px;" @click="$refs.specialInvoice.click()"
              :loading="flag.uploadSpecialInvoice" icon="el-icon-upload2">批量上传</el-button>
            <span>注明：最多一次可上传10张</span>
            <vxe-table border resizable show-overflow :data="SpecailInvoice"
              :edit-config="{ trigger: 'click', mode: 'cell' }" class="update-special-table" key="specialInvoice"
              v-if="flag.activeTab == '1'">
              <vxe-table-column align="center" field="index" title="序号" width="14%"></vxe-table-column>
              <vxe-table-column align="center" field="picUrl" title="发票图片" width="14%">
                <template #default="{ row }">
                  <el-image style="width:40px;height:40px;margin-top:5px;" :src="row.picUrl"
                    :preview-src-list="[row.picUrl]" v-if="row.picUrl"></el-image>
                  <span style="color: red;" v-else>已由系统回填信息</span>
                </template>
              </vxe-table-column>
              <vxe-table-column align="center" field="invoiceCode" title="发票代码（必填）" width="14%"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
              <vxe-table-column align="center" field="invoiceNum" title="发票号码（必填）" width="14%"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
              <vxe-table-column align="center" field="CreateTime" title="开票日期（必填）" width="14%"
                :edit-render="{ autoselect: true }">
                <template #edit="{ row }">
                  <el-input v-model="row.CreateTime" type="date" value-format="yyyy-MM-dd" />
                </template>
              </vxe-table-column>
              <vxe-table-column align="center" field="IsOcr" title="是否识别" width="14%">
                <template #default="{ row }">
                  <el-tag :type="row.IsOcr ? 'success' : 'danger'">{{ row.IsOcr ? '是' : '否' }}</el-tag>
                </template>
              </vxe-table-column>
              <!-- <vxe-table-column align="center" field="InvoiceAmount" title="开具金额（必填）" width="150"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
              <vxe-table-column align="center" field="InvoiceTotalAmount" title="价税合计" width="150"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
              <vxe-table-column align="center" field="CheckCode" title="校验码" width="150"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column> -->
              <vxe-table-column align="center" title="操作" width="16%">
                <template #default="{ row }">
                  <el-button type="danger" @click="deleteSpecialInvoiceDelete(row, row.index)">删除</el-button>
                </template>
              </vxe-table-column>
            </vxe-table>
            <!-- 分页 -->
            <el-pagination background @current-change="handleCurrentChange4" style="margin-top: 8px;"
              :current-page.sync="pagination4.page" :page-size="pagination4.pagesize"
              layout="total, prev, pager, next, jumper" :total="pagination4.total">
            </el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane label="清单" name="2">
          <div style="height: 60vh;overflow-y: scroll;">
            <!-- 提示 -->
            <el-alert style="margin-bottom: 8px;" type="warning" :closable="false">
              <span slot="title">
                <span>温馨提示：上传与发票相关的清单、票据等。</span><br />
              </span>
            </el-alert>
            <el-button type="primary" :loading="flag.uploadingInvoice" @click="$refs.voucher.click()"
              icon="el-icon-upload2">上传清单</el-button>

            <!-- 凭证列表 -->
            <div class="voucherWrap">
              <div class="item" v-for="(item, index) in checkList" :key="index">
                <i class="delete el-icon-delete" @click="handleVoucherDelete(item, pagination5.page, index)"></i>
                <el-image style="width: 200px; height: 120px; margin-bottom: 8px;" :src="item.EvidenceImgUrl"
                  :preview-src-list="[item.EvidenceImgUrl]">
                  <div slot="error" class="image-slot">
                    <img style="width: 200px; height: 120px;" @click="downloadVoucher(item.EvidenceImgUrl)"
                      :src="ossHost + 'fileDefaultBg.png'" alt="" />
                  </div>
                </el-image>
                <p @click="downloadVoucher(item.EvidenceImgUrl)">
                  {{ item.Name }}
                </p>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 按钮 -->
      <div style="text-align:center; margin-top: 8px;">
        <el-button type="primary" size="mini" :loading="flag.uploadingInvoice" v-if="flag.activeTab != 0"
          @click="handleCloseUploadInvoiceDialog">
          确认</el-button>
      </div>
    </el-dialog>

    <!-- 发送至货主dialog -->
    <el-dialog :visible.sync="flag.sendBossDialog" width="600px" class="deep_dialog"
      :close-on-click-modal="flag.sendOwnerOperate" :close-on-press-escape="flag.sendOwnerOperate">
      <span slot="title">发送至货主</span>
      <!-- 提示 -->
      <el-alert style="margin-bottom: 10px" type="warning" :closable="false">
        <span slot="title">
          <span>温馨提示：</span><br />
          <span>1.建议您将纸质发票通过物流快递寄给货主，并点击“寄送纸质票”按钮</span><br />
          <span>2.将快递公司、快递单号告知货主</span><br />
          <span>3.如与货主协商好，不寄送纸质发票，可以不填写，并点击“仅电子发票”按钮</span><br />
        </span>
      </el-alert>
      <el-form :model="sendBossForm" ref="sendBossForm" label-width="100px">
        <el-form-item label="快递公司" prop="expressName">
          <el-input v-model="sendBossForm.expressName" placeholder="请输入快递公司名称"></el-input>
        </el-form-item>
        <el-form-item label="快递单号" prop="expressNumber">
          <el-input v-model="sendBossForm.expressNumber" placeholder="请填写快递单号"></el-input>
        </el-form-item>
        <!-- 发票选择 -->
        <div style="text-align:center">
          <el-button type="primary" size="mini" @click="sendBoss(1)" :loading="flag.sendBossIng">寄送纸质票</el-button>
          <el-button type="primary" size="mini" @click="sendBoss(2)" :loading="flag.sendBossIng">仅电子发票</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 地图dialog -->
    <el-dialog width="800px" title="查看地图" :visible.sync="flag.showMap" append-to-body>
      <!-- 地图组件 -->
      <TXmap style="height:600px;" v-if="flag.showMap" :list="mapList" ref="map"></TXmap>
    </el-dialog>

    <!-- 司机信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.showDriverInfo" append-to-body>
      <DriverInfo :driverInfo="driverInfo" />
    </el-dialog>

    <!-- 车辆信息dialog -->
    <el-dialog width="1300px" :visible.sync="flag.showVehicleInfo" append-to-body>
      <VehicleInfo ref="vehicleInfo" v-if="flag.showVehicleInfo" :vehicleInfo="vehicleInfo"></VehicleInfo>
    </el-dialog>

    <!-- 上传运单错误信息dialog -->
    <el-dialog :visible.sync="flag.uploadErrorDialog" title="错误说明" center append-to-body>
      <div style="height:60vh;overflow-y:scroll">
        <el-table :data="uploadErrorList" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column align="center" prop="Hint" label="错误信息" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 已拒绝 -->
    <el-dialog :visible.sync="flag.rejectDialog" width="700px" center :close-on-press-escape="false"
      :close-on-click-modal="false">
      <div style="text-align: center">
        <i class="el-icon-error" style="color: red; font-size: 26px;"></i>
      </div>
      <h1 style="font-size: 18px; text-align: center; margin-top: 8px;">
        货主已拒绝收票
      </h1>
      <p style="text-align: left; margin: 8px 0;">
        货主“{{
          rejectName
        }}”驳回您开具的发票，您可以点击“修改发票”，重新上传发票！
      </p>
      <hr />
      <p style="text-align: left; margin: 8px 0;">驳回意见：</p>
      <div style="border: 1px solid #606266; border-radius: 5px; padding: 8px;">
        <p style="text-align: left" v-for="item in rejectContent" :key="item">
          {{ item }}
        </p>
      </div>
      <div style="text-align:center; margin-top: 8px;">
        <el-button size="mini" @click="$router.go(-1)">取消</el-button>
        <el-button type="primary" size="mini" @click="flag.rejectDialog = false">修改发票</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  earthWorkGetInvoiceDetals,
  modifyInvoiceInfo,
  billDown,
  getTaskRejectOpinion,
  DelEvidence,
  DelInvoiceInfo,
} from "@/api/supplierWaybill/basicWaybill/updateInvoice";
import {
  printDataStore,
  modifyInvoiceData,
  addInvoiceChecklist,
  specialInvoice,
  uploadInvoices,
  billForDetals,
  getInvoiceDetaleds,
  invoiceTaskDetails,
  GetInvoiceDetails,
  EidtInvoice,
  updateInvoiceStatus,
  CompleteVerify
} from "@/api/supplierWaybill/basicWaybill/makeInvoice";
import { DisInvoices, RefundWaybill } from '@/api/waybill/waybillManage/index'
import TXmap from "@/components/commonCmpt/TXmap";
import UploadImg from "@/components/commonCmpt/uploadImg";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import { getMapPath, getCarInfo, getDriverDetails } from "@/api/common/common";
import {
  _isNumberOrLetter,
  _integer,
  _polishing,
  _isAllNumber,
} from "@/utils/verify";
import { mapGetters } from "vuex";
import basicMixin from "@/mixins/basic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      flag: {
        detailDialog: false, //第四步的查看详情dialog
        editInvoiceDialog: false, //编辑发票的dialog
        sendBossDialog: false, //发送至货主
        showMap: false, //显示地图
        sendInvoice: false, //是否已经发送发票给货主了
        waybillType: "", //运单类型 货物承运 委托代征
        taskSceneTypeID: "", //场景类型
        activeTab: "0", //前激活的tab
        updateInvoiceDialog: false, //修改发票弹窗
        uploadingInvoice: false, //上传发票中
        uploadNormalInvoice: false, //上传正常发票中
        uploadSpecialInvoice: false, //上传特殊发票中
        sendBossIng: false, //发送至货主中
        showDriverInfo: false, //司机信息弹窗
        showVehicleInfo: false, //车辆弹窗信息
        dialogLoading: false, //dialog弹窗是否是loading状态
        uploadErrorDialog: false, //发送至货主错误信息
        rejectDialog: false, //货主驳回意见框
        sendOwnerOperate: false
      },
      driverInfo: {}, //司机信息
      tableDataTotal: [], //表格列表
      wayBillTotal: {}, //顶部卡片总计
      consign: {}, //顶部卡片总计
      tableData: [], //查看详情的表格
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination3: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination4: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination5: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      paginationElectron: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      sendBossForm: {
        expressName: "", //快递名称
        expressNumber: "", //快递单号
      },
      //设置当前组件正在操作的文件
      currentFile: null,
      currentIndex: null, //当前详情的item对象
      currentInvoiceNumber: "", //当前发票号码
      PrintTaskID: "", //打票单号
      mapList: [], //轨迹列表
      uploadErrorList: [], //发送至货主错误信息
      rejectContent: "", //货主驳回意见
      rejectName: "", //拒绝服务商的货主名称
    };
  },
  methods: {
    //打开查看详情发票
    openUpdateInvoiceDialog(item, index) {
      this.currentIndex = index
      this.pagination3.total = this.currentTabledata[this.currentIndex].invoiceImgList.length;
      this.pagination4.total = this.currentTabledata[this.currentIndex].specialInvoiceList.length;
      this.paginationElectron.total = this.currentTabledata[this.currentIndex].electronInvoiceList.length;
      this.flag.updateInvoiceDialog = true;
    },
    // 返回待办
    goBack() {
      this.$router.push({
        path: "/commission/pendingManage/pendingDetail",
        query: {
          currentStep: this.$route.query.currentStep,
          enterpriseFullName: this.$route.query.enterpriseFullName,
        },
      });
    },
    //暂存
    printDataStore(type) {
      // this.printDataStoreIng = true
      let invoices = []; //正常发票
      let specials = []; //特殊发票
      let checks = []; //凭证
      this.currentTabledata[this.currentIndex].specialInvoiceList.forEach((el) => {
        if (el.IsOcr) {
          invoices.push({
            InvoiceImgUrl: el.picUrl, //发票图片地址
            InvoiceNum: el.invoiceNum, //发票号码
            PrintInvoiceTaskID: this.currentTabledata[this.currentIndex].PrintTaskID, //打票单号
            InvoiceCode: el.invoiceCode,
            InvoiceTime: el.CreateTime,
            AmountIssued: el.money || 0, //开具金额
            TaxIncreaseAlloy: el.total || 0, //加税合计
            CheckCode: el.verifyCode, //校验码
          })
        } else {
          specials.push({
            InvoiceImgUrl: el.picUrl, //发票图片地址
            InvoiceNum: el.invoiceNum, //发票号码
            PrintInvoiceTaskID: this.currentTabledata[this.currentIndex].PrintTaskID, //打票单号
            InvoiceCode: el.invoiceCode,
            InvoiceTime: el.CreateTime,
            AmountIssued: el.money || 0, //开具金额
            TaxIncreaseAlloy: el.total || 0, //加税合计
            CheckCode: el.verifyCode, //校验码
          })
        }
      });
      this.currentTabledata[this.currentIndex].checkList.forEach((el) => {
        checks.push({
          ImageUrl: el.EvidenceImgUrl,
          PrintTaskId: this.currentTabledata[this.currentIndex].PrintTaskID, //打票单号
          Name: el.name,
        });
      });
      printDataStore({
        Json: JSON.stringify({
          InvoiceTaskId: this.getInvoiceTaskID,
          expressName: "",
          trackingNum: "",
          Status: "",
          Invoices: invoices,
          Specials: specials,
          Checks: checks,
          bOnlyInsertData: false, // 是否新增发票
          IsUpdatStaging: true, // 是否为修改发票
          PrintTaskID: this.currentTabledata[this.currentIndex].PrintTaskID,
          PrintStatus: 2, // 2为暂存后还是为修改状态
        }),
      })
        .then((res) => {
          this.billForDetals()
          this.flag.activeTab = '0'
          if (type == "autoInvoice") {
            return;
          } else if (type == "paperInvoice") {
            return;
          } else {
            this.$router.go(-1);
          }
        })
        .catch((res) => {
          this.uploadErrorList = res.data.invoices;
          this.flag.uploadErrorDialog = true;
        })
        .finally(() => {
          // this.printDataStoreIng = false
        });
    },
    // 获取电子发票
    async getInvoiceDetails(printTaskID) {
      await GetInvoiceDetails({ printTaskID: printTaskID }).then((res) => {
        if (res.data.InvoiceList) {
          this.currentTabledata[this.currentIndex].electronInvoiceList = res.data.InvoiceList.map(
            (item, index) => {
              item.index = index + 1;
              return item;
            }
          ).filter((i) => {
            return i.Medium == 1;
          });
          this.paginationElectron.total = this.currentTabledata[this.currentIndex].electronInvoiceList.length;
        }
      });
    },
    //电子发票查询、冲红、重开
    batchInvoiceAction(type, row) {
      let params = {
        action: type,
        listInvoice: [],
      };
      if (row) {
        params.listInvoice = [
          {
            printTaskID: row.PrintTaskID,
            invoiceSerialNum: row.InvoiceSerialNum,
          },
        ];
      } else {
        this.chosedElectronInvoice.map((item) => {
          params.listInvoice.push({
            printTaskID: item.PrintTaskID,
            invoiceSerialNum: item.InvoiceSerialNum,
          });
        });
      }
      let text = "";
      if (type == 1) {
        text = "开票中...";
      } else if (type == 3) {
        text = "下载中...";
      } else if (type == 4) {
        text = "查询中...";
      }
      if (type != 3) {
        const loading = this.$loading({
          lock: true,
          text: text,
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        BatchInvoiceAction(params)
          .then((res) => {
            this.getInvoiceDetails(this.currentTabledata[this.currentIndex].PrintTaskID);
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
      } else {
        window.open(row, "_blank");
      }
    },
    //下载凭证
    downloadVoucher(url) {
      window.open(url);
    },
    //查看司机信息
    openDerverInfo(UserID) {
      this.flag.dialogLoading = true;
      let data = {};
      getDriverDetails({ UserID: UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.flag.showDriverInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //查看车辆信息
    openVehicleInfo(item) {
      this.flag.dialogLoading = true;
      let params = {
        CarNumber: item.LicensePlate ? item.LicensePlate : item.CarNumber,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.showVehicleInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //关闭修改弹窗
    handleCloseUploadInvoiceDialog() {
      if (this.flag.activeTab == 0) {
        this.flag.updateInvoiceDialog = false;
        return;
      }
      let flag = false; //是否还有必填项每天
      this.currentTabledata[this.currentIndex].specialInvoiceList.forEach(item => {
        if (!item.invoiceCode || !item.invoiceNum || !item.CreateTime) {
          flag = true
        }
      })
      if (flag) {
        this.$message.warning(
          "发票代码、发票号码、开票日期都为必填项"
        );
      } else {
        console.log(222)
        try {
          this.currentTabledata[this.currentIndex].specialInvoiceList.forEach(item => {
            this.currentTabledata[this.currentIndex].specialInvoiceList.forEach(el => {
              if (item.invoiceCode === el.invoiceCode && item.invoiceNum === el.invoiceNum && item.index != el.index) {
                this.$message.error('发票中有重复发票')
                throw new Error()
              }
            })
            if (!_isNumberOrLetter(item.invoiceCode)) {
              this.$message.error(`发票-序号${item.index}的发票代码只能为数字和字母`)
              throw new Error()
            }
            if (item.invoiceCode.length > 32) {
              this.$message.error(`发票-序号${item.index}的发票代码需小于32个字符`)
              throw new Error()
            }
            if (!_isNumberOrLetter(item.invoiceNum)) {
              this.$message.error(`发票-序号${item.index}的发票号码只能为数字和字母`)
              throw new Error()
            }
            if (item.invoiceNum.length > 32) {
              this.$message.error(`发票-序号${item.index}的发票号码需小于32个字符`)
              throw new Error()
            }
            // if (!_integer(item.money)) {
            //   this.$message.error(`发票-序号${item.index}的开具金额只能为数字，且仅保留两位小数`)
            //   throw new Error()
            // }
            // if (Number(item.money) > 10000000) {
            //   this.$message.error(`特殊发票-序号${item.index}的开具金额不能大于一千万`)
            //   throw new Error()
            // }
            // if (item.total) {
            //   if (!_integer(item.total)) {
            //     this.$message.error(`特殊发票-序号${item.index}的价税合计只能为数字，且仅保留两位小数`)
            //     throw new Error()
            //   }
            //   if (Number(item.total) > 20000000) {
            //     this.$message.error(`特殊发票-序号${item.index}的价税合计不能大于二千万`)
            //     throw new Error()
            //   }
            // }
            // if (item.verifyCode) {
            //   if (!_isAllNumber(item.verifyCode)) {
            //     this.$message.error(`特殊发票-序号${item.index}的校验码只能为纯数字`)
            //     throw new Error()
            //   }
            // }
          })
          let evidenceList = []
          let invoiceList = []
          this.currentTabledata[this.currentIndex].checkList.map(item => {
            evidenceList.push(item.EvidenceImgUrl)
          })
          this.currentTabledata[this.currentIndex].specialInvoiceList.map(item => {
            invoiceList.push({
              invoiceImgUrl: item.picUrl,
              invoiceNumber: item.invoiceNum,
              invoiceCode: item.invoiceCode,
              createTime: item.CreateTime,
              isOcr: item.IsOcr,
            })
          })
          EidtInvoice({
            printTaskID: this.currentTabledata[this.currentIndex].PrintTaskID,
            invoiceList,
            evidenceList
          }).then(res => {
            this.flag.updateInvoiceDialog = false;
            this.printDataStore("paperInvoice");
          })
        } catch (error) {
          console.log(error);
        }
      }
    },
    //删除正常发票
    handleInvoiceDelete(item, index) {
      this.$confirm("确认删除该发票吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (!item.PrintTaskID) {
            this.pagination3.total = this.pagination3.total - 1;
            this.currentTabledata[this.currentIndex].invoiceImgList.splice(index, 1);
            this.$message.success("删除成功");
          } else {
            DelInvoiceInfo({
              printTaskID: item.PrintTaskID,
              invoiceNumber: item.InvoiceNumber,
            })
              .then((res) => {
                this.pagination3.total = this.pagination3.total - 1;
                this.currentTabledata[this.currentIndex].invoiceImgList.splice(index, 1);
                this.$message.success("删除成功");
              })
              .catch(() => {
                this.$message.error("删除失败");
              });
          }
        })
        .catch();
    },
    //上传正常发票
    handleInvoiceUpload(e) {
      let fileList = e.srcElement.files || e.target.files;
      if (fileList.length > 10) {
        this.$message.error("单次上传不可超过十张发票");
        this.$refs.invoice.value = null;
        return;
      }
      this.flag.uploadingInvoice = true;
      this.flag.uploadNormalInvoice = true;
      let count = 0; //已经上传完毕了多少条
      let fileListLength = fileList.length;
      for (let i = 0; i < fileListLength; i++) {
        let formData = new FormData();
        formData.append("file", fileList[i]);
        uploadInvoices(formData)
          .then((res) => {
            if (
              !res.photoInfo[0].OcrInfo.data.发票号码 ||
              !res.photoInfo[0].OcrInfo.data.发票代码
            ) {
              this.$message.warning(
                "该发票识别不出发票号码等信息，请上传到特殊发票中再手动输入信息"
              );
              return;
            }
            this.tableDataTotal.forEach((item) => {
              if (item.PrintTaskID == this.currentTabledata[this.currentIndex].PrintTaskID) {
                let hasSame = false;
                item.invoiceImgList.forEach((el) => {
                  if (
                    el.InvoiceNumber ===
                    res.photoInfo[0].OcrInfo.data.发票号码 &&
                    el.InvoiceCode === res.photoInfo[0].OcrInfo.data.发票代码
                  ) {
                    hasSame = true;
                  }
                });
                //如果有相同的
                if (hasSame) {
                  this.$message.warning("请勿上传重复的发票");
                } else {
                  //没有相同的
                  //处理实例存储的发票号
                  //处理dialog组件的发票号
                  item.invoiceImgList.push({
                    index: item.invoiceImgList.length + 1,
                    InvoiceImgUrl: res.photoInfo[0].PhotoUrl,
                    InvoiceNumber: res.photoInfo[0].OcrInfo.data.发票号码,
                    InvoiceContent: res.photoInfo[0].OcrInfo.data,
                    InvoiceAmount: res.photoInfo[0].OcrInfo.data.不含税金额, //开具金额
                    InvoiceTotalAmount: res.photoInfo[0].OcrInfo.data.发票金额, //价税合计
                    CheckCode: res.photoInfo[0].OcrInfo.data.校验码, //校验码
                    InvoiceCode: res.photoInfo[0].OcrInfo.data.发票代码, //发票代码
                    CreateTime: res.photoInfo[0].OcrInfo.data.开票日期
                      .replace(/年/g, "-")
                      .replace(/月/g, "-")
                      .replace(/日/g, ""),
                  });
                  this.pagination3.total = this.pagination3.total + 1;
                }
              }
            });
          })
          .finally(() => {
            this.$refs.invoice.value = null;
            count = count + 1;
            if (count === fileListLength) {
              this.flag.uploadingInvoice = false;
              this.flag.uploadNormalInvoice = false;
            }
          });
      }
    },
    //上传特殊发票信息
    handleSpecialInvoiceUpload(e) {
      let fileList = e.srcElement.files || e.target.files;
      if (fileList.length > 10) {
        this.$message.error("单次上传不可超过十张发票");
        this.$refs.specialInvoice.value = null;
        return;
      }
      this.flag.uploadingInvoice = true;
      this.flag.uploadSpecialInvoice = true;
      let count = 0; //已经上传完毕了多少条
      let fileListLength = fileList.length;
      for (let i = 0; i < fileListLength; i++) {
        let formData = new FormData();
        formData.append("file", fileList[i]);
        DisInvoices(formData)
          .then((res) => {
            this.currentTabledata[this.currentIndex].specialInvoiceList.push({
              index: this.currentTabledata[this.currentIndex].specialInvoiceList.length + 1,
              picUrl: res.data.InvoiceImgUrl,
              invoiceNum: res.data.InvoiceNum,
              invoiceCode: res.data.InvoiceCode, //发票代码
              money: res.data.AmountIssued, //开具金额
              total: res.data.TaxIncreaseAlloy, //价税合计
              verifyCode: res.data.CheckCode, //校验码
              CreateTime: res.data.InvoiceTime,
              IsOcr: res.data.IsOcr // 是否Ocr识别
            });
            this.pagination4.total = this.pagination4.total + 1;
          })
          .finally(() => {
            this.$refs.specialInvoice.value = null;
            count = count + 1;
            if (count === fileListLength) {
              this.flag.uploadingInvoice = false;
              this.flag.uploadSpecialInvoice = false;
            }
          });
      }
    },
    //删除特殊发票
    deleteSpecialInvoiceDelete(item, index) {
      this.$confirm("确认删除该发票吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.currentTabledata[this.currentIndex].specialInvoiceList.forEach((item, inx) => {
            if (item.index == index) {
              this.pagination4.total = this.pagination4.total - 1;
              this.currentTabledata[this.currentIndex].specialInvoiceList.splice(inx, 1);
            }
          });
          this.currentTabledata[this.currentIndex].specialInvoiceList.forEach((item, index) => {
            item.index = index + 1;
          });
          this.$message.success("删除成功");
          // if (!item.PrintTaskID) {
          //   this.currentTabledata[this.currentIndex].specialInvoiceList.forEach((item, inx) => {
          //     if (item.index == index) {
          //       this.pagination4.total = this.pagination4.total - 1
          //       this.currentTabledata[this.currentIndex].specialInvoiceList.splice(inx, 1)
          //     }
          //   })
          //   this.currentTabledata[this.currentIndex].specialInvoiceList.forEach((item, index) => {
          //     item.index = index + 1
          //   })
          //   this.$message.success("删除成功");
          // } else {
          //   DelInvoiceInfo({
          //     printTaskID: this.currentTabledata[this.currentIndex].PrintTaskID,
          //     invoiceNumber: item.InvoiceNumber,
          //   })
          //     .then((res) => {
          //       this.currentTabledata[this.currentIndex].specialInvoiceList.forEach((item, inx) => {
          //         if (item.index == index) {
          //           this.pagination4.total = this.pagination4.total - 1;
          //           this.currentTabledata[this.currentIndex].specialInvoiceList.splice(inx, 1);
          //         }
          //       });
          //       this.currentTabledata[this.currentIndex].specialInvoiceList.forEach((item, index) => {
          //         item.index = index + 1;
          //       });
          //       this.$message.success("删除成功");
          //     })
          //     .catch(() => {
          //       this.$message.error("删除失败");
          //     });
          // }
          // DelInvoiceInfo({
          //   printTaskID: this.currentTabledata[this.currentIndex].PrintTaskID,
          //   invoiceNumber: item.invoiceNum,
          // })
          //   .then((res) => {
          //     this.currentTabledata[this.currentIndex].specialInvoiceList.forEach((item, inx) => {
          //       if (item.index == index) {
          //         this.pagination4.total = this.pagination4.total - 1;
          //         this.currentTabledata[this.currentIndex].specialInvoiceList.splice(inx, 1);
          //       }
          //     });
          //     this.currentTabledata[this.currentIndex].specialInvoiceList.forEach((item, index) => {
          //       item.index = index + 1;
          //     });
          //     this.$message.success("删除成功");
          //   })
          //   .catch(() => {
          //     this.$message.error("删除失败");
          //   });
        })
        .catch();
    },
    //上传清单
    handleVoucherUpload(e) {
      this.flag.uploadingInvoice = true;
      let formData = new FormData();
      formData.append("file", e.srcElement.files[0] || e.target.files[0]);
      addInvoiceChecklist(formData)
        .then((res) => {
          this.currentTabledata[this.currentIndex].checkList.push({
            index: this.currentTabledata[this.currentIndex].checkList.length + 1,
            EvidenceImgUrl: res.checkList.ImageUrl,
            Name: res.checkList.Name,
          });
          this.pagination5.total = this.pagination5.total + 1;
        })
        .finally(() => {
          this.$refs.voucher.value = null;
          this.flag.uploadingInvoice = false;
        });
    },
    //删除一个凭证
    handleVoucherDelete(item, page, index) {
      this.$confirm("确认删除该凭证吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.pagination5.total = this.pagination5.total - 1;
          this.currentTabledata[this.currentIndex].checkList.splice(
            (page - 1) * this.pagination5.pagesize + index,
            1
          );
          this.$message.success("删除成功");
          // if (!item.EvidenceID) {
          //   this.pagination5.total = this.pagination5.total - 1;
          //   this.currentTabledata[this.currentIndex].checkList.splice(
          //     (page - 1) * this.pagination5.pagesize + index,
          //     1
          //   );
          //   this.$message.success("删除成功");
          // } else {
          //   DelEvidence({ evidenceID: item.EvidenceID })
          //     .then((res) => {
          //       this.pagination5.total = this.pagination5.total - 1;
          //       this.currentTabledata[this.currentIndex].checkList.splice(
          //         (page - 1) * this.pagination5.pagesize + index,
          //         1
          //       );
          //       this.$message.success("删除成功");
          //     })
          //     .catch(() => {
          //       this.$message.error("删除失败");
          //     });
          // }
        })
        .catch();
    },

    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //获取列表
    billForDetals() {
      if (!this.getInvoiceTaskID) {
        this.$router.push("/commission/pendingManage/index");
        return;
      }
      let params = {
        pageIndex: 1,
        // PageSize: this.pagination1.pagesize,
        PageSize: 100000, //一次性把数据全部拿回来然后前端自己分页
        InvoiceTaskID: this.getInvoiceTaskID,
        CarNumber: "",
        WaybillID: "",
        InvoiceNumber: "",
        VehicleCarNumber: "",
        PrincipalCompany: "",
        DriverPhone: "",
        StartDatetime: "",
        EndDatetime: "",
        TaskDatetime: "",
      };
      billForDetals({ Json: JSON.stringify(params) }).then((res) => {
        this.wayBillTotal = res.wayBill;
        this.consign = res.Consign;
        this.tableDataTotal = res.invoices.map((item, index) => {
          item.electronInvoiceList = []; //电子发票列表
          item.invoiceImgList = []; //正常发票列表
          item.specialInvoiceList = [] //特殊发票列表
          item.voucherList = [] //凭证清单列表
          item.invoiceInfos.map((el, inx) => {
            if (el.Medium == 1) {
              item.electronInvoiceList.push({
                index: inx + 1,
                ...el,
              });
            }
            if (el.Medium == 0) {
              // item.invoiceImgList.push({
              //   index: inx + 1,
              //   ...el,
              //   // picUrl: el.InvoiceImgUrl,
              //   // invoiceNum: el.InvoiceNumber,
              //   // invoiceContent: '',
              //   // money: el.InvoiceAmount, //开具金额
              //   // total: el.InvoiceTotalAmount, //价税合计
              //   // verifyCode: el.CheckCode, //校验码
              //   // invoiceCode: el.InvoiceCode,
              //   // CreateTime: el.CreateTime
              // });
              item.invoiceImgList.push({
                index: inx + 1,
                picUrl: el.InvoiceImgUrl,
                invoiceNum: el.InvoiceNumber,
                invoiceContent: '',
                money: el.InvoiceAmount, //开具金额
                total: el.InvoiceTotalAmount, //价税合计
                verifyCode: el.CheckCode, //校验码
                invoiceCode: el.InvoiceCode,
                CreateTime: el.CreateTime,
                IsOcr: el.IsOcr
              })
              item.specialInvoiceList.push({
                index: inx + 1,
                picUrl: el.InvoiceImgUrl,
                invoiceNum: el.InvoiceNumber,
                invoiceContent: '',
                money: el.InvoiceAmount, //开具金额
                total: el.InvoiceTotalAmount, //价税合计
                verifyCode: el.CheckCode, //校验码
                invoiceCode: el.InvoiceCode,
                CreateTime: el.CreateTime,
                IsOcr: el.IsOcr
              })
            }
          });
          item.SpecailInvoice.forEach((el, inx) => {
            item.specialInvoiceList.push({
              index: inx + 1,
              invoiceCode: el.InvoiceCode,
              invoiceNum: el.InvoiceNumber,
              picUrl: el.InvoiceImgUrl,
              money: el.InvoiceAmount,
              total: el.InvoiceTotalAmount,
              CreateTime: el.CreateTime,
              verifyCode: el.CheckCode,
              IsOcr: el.IsOcr
            })
          })
          item.index = index + 1;
          return item;
        });
        this.flag.waybillType = res.invoices[0].TaskType;
        this.flag.taskSceneTypeID = res.invoices[0].TaskSceneTypeID;
        this.pagination1.total = Number(res.totalRowCount);
      });
    },
    //查看详情
    _detail(item, index) {
      const loading = this.$loading({
        lock: true,
        text: '请求中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.PrintTaskID = item.PrintTaskID;
      this.currentIndex = index
      this.currentTabledata[this.currentIndex] = JSON.parse(JSON.stringify(item));
      let params = {
        InvoiceTaskID: this.PrintTaskID,
        pageIndex: 1,
        pageSize: 10000,
      };
      //货物承运
      if (this.flag.waybillType == "01" && this.flag.taskSceneTypeID == "1") {
        getInvoiceDetaleds({ Json: JSON.stringify(params) }).then((res) => {
          this.tableData = res.invoices.map((item, index) => {
            item.index = index + 1;
            return item;
          });
          this.flag.detailDialog = true;
        }).finally(() => {
          loading.close()
        });
        //土石方
      } else if (
        this.flag.waybillType == "01" &&
        this.flag.taskSceneTypeID == "6"
      ) {
        earthWorkGetInvoiceDetals({ Json: JSON.stringify(params) }).then(
          (res) => {
            this.tableData = res.invoices.map((item, index) => {
              item.index = index + 1;
              return item;
            });
            this.flag.detailDialog = true;
          }).finally(() => {
            loading.close()
          });
        //交通运输
      } else if (
        (this.flag.waybillType == "02" &&
          this.flag.taskSceneTypeID == "3") || this.$entrustSceneIds.includes(Number(this.flag.taskSceneTypeID))
      ) {
        invoiceTaskDetails({ Json: JSON.stringify(params) }).then((res) => {
          this.tableData = res.invoices.map((item, index) => {
            item.index = index + 1;
            return item;
          });
          this.flag.detailDialog = true;
        }).finally(() => {
          loading.close()
        });
      }
    },
    //分页回调
    handleCurrentChange1(e) {
      this.pagination1.page = e;
    },
    //分页回调
    handleCurrentChange3(e) {
      this.pagination3.page = e;
    },
    //分页回调
    handleCurrentChange4(e) {
      this.pagination4.page = e;
    },
    //分页回调
    handleCurrentChange5(e) {
      this.pagination5.page = e;
    },
    // 电子发票分页
    handleCurrentChangeElectron(e) {
      this.paginationElectron.page = e;
    },
    //下载表格运单
    billDown() {
      let params = {
        invoiceTaskId: this.getInvoiceTaskID
      }
      RefundWaybill(params).then(res => {
        window.location.href = res.data
      })
    },
    //确认发送至货主 -> 寄送纸质票
    sendBoss(status) {
      CompleteVerify({
        invoiceTaskID: this.getInvoiceTaskID
      }).then(res => {
        if (res.data) {
          confirmSend(status)
        } else {
          this.$confirm('您还未“上传运费发票”，是否继续？', '提示', {
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            confirmSend(status)
          }).catch(() => {

          });
          // this.$message.error("您还未“上传运费发票”，请先上传");
          return;
        }
      })
    },
    confirmSend(status) {
      //纸质发票
      if (status == 1) {
        if (
          !this.sendBossForm.expressName ||
          !this.sendBossForm.expressNumber
        ) {
          this.$message.warning("请填写快递公司和快递单号");
          return;
        }
      }
      this.flag.sendOwnerOperate = false;
      this.flag.sendBossIng = true;
      updateInvoiceStatus({
        Json: JSON.stringify({
          InvoiceTaskId: this.getInvoiceTaskID,
          expressName: this.sendBossForm.expressName,
          trackingNum: this.sendBossForm.expressNumber,
          Status: status,
        }),
      })
        .then((res) => {
          this.flag.sendBossDialog = false;
          this.flag.sendInvoice = true;
          this.flag.sendOwnerOperate = true;
        })
        .catch((res) => {
          this.uploadErrorList = res.data.invoices;
          this.flag.uploadErrorDialog = true;
        })
        .finally(() => {
          this.flag.sendBossIng = false;
        });
    },
    //打开地图
    openMap(item) {
      this.flag.dialogLoading = true;
      let params = {
        wayBillId: item.WaybillNumber ? item.WaybillNumber : item.WaybillID,
        searchWord: item.LicensePlate ? item.LicensePlate : item.CarNumber,
        stDate: item.Loading_time ? item.Loading_time : item.LoadingDatetime,
        edDate: item.Submission_time ? item.Submission_time : item.SignDatetime,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //获取被货主驳回的原因
    getTaskRejectOpinion() {
      let params = {
        taskId: this.getInvoiceTaskID,
      };
      getTaskRejectOpinion(params).then((res) => {
        this.rejectContent = res.data;
        this.rejectName = res.name;
        this.flag.rejectDialog = true;
      });
    },
  },
  created() {
    //获取运单列表
    this.billForDetals();
    // this.getTaskRejectOpinion()
  },
  computed: {
    ...mapGetters(["getInvoiceTaskID"]),
    currentTabledata() {
      return this.tableDataTotal
        .slice(
          this.pagination1.pagesize * (this.pagination1.page - 1),
          this.pagination1.pagesize * this.pagination1.page
        )
        .map((item) => {
          let invoiceNumber = [];
          let invoiceDate = [];
          item.electronInvoiceList.forEach((el) => {
            invoiceNumber.push(el.InvoiceNumber);
            invoiceDate.push(el.CreateTime);
          });
          // item.invoiceImgList.forEach((el) => {
          //   invoiceNumber.push(el.InvoiceNumber);
          //   invoiceDate.push(el.CreateTime);
          // });
          item.specialInvoiceList.forEach((el) => {
            invoiceNumber.push(el.invoiceNum);
            invoiceDate.push(el.CreateTime);
          });
          item._invoiceNumber = invoiceNumber.join(",");
          item.CreateTime = invoiceDate.join(",");
          return item;
        });
    },
    //正常发票分页
    invoiceImgList() {
      try {
        return this.currentTabledata[this.currentIndex].invoiceImgList.slice(
          this.pagination3.pagesize * (this.pagination3.page - 1),
          this.pagination3.pagesize * this.pagination3.page
        );
      } catch (error) { }
    },
    //特殊发票分页
    SpecailInvoice() {
      try {
        return this.currentTabledata[this.currentIndex].specialInvoiceList.slice(
          this.pagination4.pagesize * (this.pagination4.page - 1),
          this.pagination4.pagesize * this.pagination4.page
        );
      } catch (error) { }
    },
    //发票凭证分页
    checkList() {
      try {
        return this.currentTabledata[this.currentIndex].checkList.slice(
          this.pagination5.pagesize * (this.pagination5.page - 1),
          this.pagination5.pagesize * this.pagination5.page
        );
      } catch (error) { }
    },
    //电子发票分页
    electronInvoiceList() {
      try {
        return this.currentTabledata[this.currentIndex].electronInvoiceList.slice(
          this.paginationElectron.pagesize * (this.paginationElectron.page - 1),
          this.paginationElectron.pagesize * this.paginationElectron.page
        );
      } catch (error) { }
    },
  },
  components: {
    UploadImg,
    VehicleInfo,
    DriverInfo,
    TXmap,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.facilityBox {
  .sumArea {
    .settleBox {
      margin-bottom: 10px;
      padding: 10px 16px;
      background-color: #f0f0f0;
      color: #666;
    }

    .total {
      flex: 1;
      background: $primary;
      display: flex;
      flex-wrap: wrap;
      color: white;
      padding: 10px;
      border-radius: 5px;

      div {
        width: 20%;
      }
    }
  }

  .pagination1 {
    // text-align: right;
    margin-top: 16px;
  }

  .updateInvoiceDialog {
    .voucherWrap {
      display: flex;
      flex-wrap: wrap;
      width: 1100px;

      .item {
        width: 18%;
        margin: 16px 10px;
        position: relative;

        .delete {
          cursor: pointer;
          position: absolute;
          font-size: 30px;
          position: absolute;
          bottom: 30px;
          right: 30px;
          z-index: 9999;
        }

        p {
          width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: blue;
          cursor: pointer;
        }
      }
    }

    .uploadImgWrap {
      display: flex;
      flex-wrap: wrap;
      width: 1100px;
      min-height: 50px;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 18%;
        margin: 16px 10px;
        position: relative;
        display: flex;
        flex-direction: column;

        .delete {
          cursor: pointer;
          position: absolute;
          font-size: 30px;
          position: absolute;
          bottom: 30px;
          right: 30px;
          z-index: 9999;
        }

        span {
          width: 200px;
          text-align: center;
        }
      }
    }
  }
}

.special-table {
  .vxe-table--empty-placeholder {
    top: 50px !important;
  }

  table {
    width: 100% !important;
  }

  th,
  td {
    width: auto !important;

  }
}
</style>
<style>
.updateInvocie .updateInvoiceDialog .uploadImgWrap .el-input__inner {
  width: 150px !important;
}

.update-special-table .vxe-table--empty-placeholder {
  top: 50px !important;
}
</style>
