import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//获取被货主驳回的原因
export const getTaskRejectOpinion = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/GetTaskRejectOpinion?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//开票完成（服务商修改页调的接口）
export const modifyInvoiceInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Invoice/ModifyInvoiceInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//下载发票运单表格
export const billDown = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/File/BillDown`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//查看发票详情
export const earthWorkGetInvoiceDetals = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/EarthWork/EarthWorkGetInvoiceDetals`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除清单凭证
export const DelEvidence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Evidence/DelEvidence`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 删除发票
export const DelInvoiceInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceInfo/DelInvoiceInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}